import { generatePath } from "react-router-dom";
import { CarDocument, CarRequest, CarResponse } from "Operations";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/cars/:id?`, { id: id ? id : null });

export const useGetCars = (initialParams: CarRequest) => {
    return useGetPagedQuery<CarResponse, CarRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetCar = (id?: string) => {
    return useGetQuery<CarDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateCar = (id?: string) => {
    return useCreateOrUpdateMutation<CarDocument, CarDocument>({ app: "operations", url: url(id), id: id });
};
