const common = {
    id: "id",
    yes: "yes",
    no: "no",
    true: "true",
    false: "false",
    remove: "remove",
    goTo: "goTo",
    add: "add",
    paginationInfo: "paginationInfo",
    paginationRowsPerPage: "paginationRowsPerPage",
    areYouSure: "areYouSure",
    editedBy: "editedBy",
    fullName: "fullName",
    phoneNumber: "phoneNumber",
    email: "email",
    billingAddress: "billingAddress",
    change: "change",
    agree: "agree",
    decline: "decline",
    membershipPoints: "membershipPoints",
    membershipLevel: "membershipLevel",
    accountNumber: "accountNumber",
    accountNumberFrs: "accountNumberFrs",
    accountNumberF24: "accountNumberF24",
    companyName: "companyName",
    vatin: "vatin",
    postcode: "postcode",
    city: "city",
    street: "street",
    buildingNumber: "buildingNumber",
    apartmentNumber: "apartmentNumber",
    invoiceInfo: "invoiceInfo",
    address: "address",
    settings: "settings",
    temponarySettings: "temponarySettings",
    mobileNumber: "mobileNumber",
    edit: "edit",
    firstName: "firstName",
    lastName: "lastName",
    password: "password",
    passwordRepetition: "passwordRepetition",
    submit: "submit",
    balance: "balance",
    comment: "comment",
    isImportant: "isImportant",
    createdBy: "createdBy",
    isActive: "isActive",
    createdAt: "createdAt",
    shop: "shop",
    balanceAmount: "balanceAmount",
    modificationType: "modificationType",
    modificationTypeDescription: "modificationTypeDescription",
    kind: "kind",
    usage: "usage",
    value: "value",
    validFrom: "validFrom",
    validTo: "validTo",
    start: "start",
    end: "end",
    campaignName: "campaignName",
    requirements: "requirements",
    voucher: "voucher",
    createVoucher: "createVoucher",
    details: "details",
    type: "type",
    search: "search",
    balanceTotal: "balanceTotal",
    balanceFRS: "balanceFRS",
    balanceF24: "balanceF24",
    customer: "customer",
    frsRefund: "frsRefund",
    f24Refund: "f24Refund",
    isNotifying: "isNotifying",
    customMessage: "customMessage",
    reason: "reason",
    balanceChangeReason: "balanceChangeReason",
    save: "save",
    note: "note",
    updatedAt: "updatedAt",
    active: "active",
    name: "name",
    priority: "priority",
    waterLimits: "waterLimits",
    productLimits: "productLimits",
    productRestrictions: "productRestrictions",
    orderMinimumValue: "orderMinimumValue",
    orderMaximumValue: "orderMaximumValue",
    isMultipackAggregationDisabled: "isMultipackAggregationDisabled",
    globalLimit: "globalLimit",
    mappedProductId: "mappedProductId",
    product: "product",
    productPredicates: "productPredicates",
    productId: "productId",
    products: "products",
    limit: "limit",
    feed: "feed",
    productContent: "productContent",
    discounts: "discounts",
    promotions: "promotions",
    contracts: "contracts",
    key: "key",
    contentData: "contentData",
    totalFrom: "totalFrom",
    waterLimitInLiters: "waterLimitInLiters",
    containerName: "containerName",
    categoryName: "categoryName",
    cms: "cms",
    contests: "contests",
    contestName: "contestName",
    campaings: "campaings",
    categoryBoost: "categoryBoost",
    phraseBoost: "phraseBoost",
    keywords: "keywords",
    campaingsForm: "campaingsForm",
    newItem: "newItem",
    boostLevel: "boostLevel",
    categoryBoostForm: "categoryBoostForm",
    equivalents: "equivalents",
    replacements: "replacements",
    replacementTerm: "replacementTerm",
    replaceFor: "replaceFor",
    replacementDeleteTermTitle: "replacementDeleteTermTitle",
    replacementDeleteTermText: "replacementDeleteTermText",
    equivalentsTerms: "equivalentsTerms",
    equivalentsDeleteTermsTitle: "equivalentsDeleteTermsTitle",
    equivalentsDeleteTermsText: "equivalentsDeleteTermsText",
    term: "term",
    customers: "customers",
    customerInfo: "customerInfo",
    ordersList: "ordersList",
    customerWithId: "customerWithId",
    editCustomerWithId: "editCustomerWithId",
    accountType: "accountType",
    lastActiveAt: "lastActiveAt",
    searchBy: "searchBy",
    searchByVoucherId: "searchByVoucherId",
    complaintsDataTab: "complaintsDataTab",
    paymentDataTab: "paymentDataTab",
    gdprTab: "gdprTab",
    consents: "consents",
    consentChange: "consentChange",
    consentChannel: "consentChannel",
    consentChangeQuestion: "consentChangeQuestion",
    export: "export",
    exportWithConsents: "exportWithConsents",
    personalInfo: "personalInfo",
    homeAccount: "homeAccount",
    testAccount: "testAccount",
    specialClient: "specialClient",
    noProductLimits: "noProductLimits",
    noWaterLimits: "noWaterLimits",
    vipAccount: "vipAccount",
    disableAlerts: "disableAlerts",
    danoneWorkers: "danoneWorkers",
    nutriciaWorkers: "nutriciaWorkers",
    friscoWorkers: "friscoWorkers",
    fullMembership: "fullMembership",
    disablePersonalization: "disablePersonalization",
    disableReview: "disableReview",
    disableOrderLimits: "disableOrderLimits",
    disableMails: "disableMails",
    collectiveInvoice: "collectiveInvoice",
    electronicInvoice: "electronicInvoice",
    unlimitedWindows: "unlimitedWindows",
    forceWarehousePOZ: "forceWarehousePOZ",
    forceWarehouseWAW: "forceWarehouseWAW",
    forceWarehouseWA3: "forceWarehouseWA3",
    forceWarehouseWRO: "forceWarehouseWRO",
    deleteCustomer: "deleteCustomer",
    removeCustomerQuestion: "removeCustomerQuestion",
    loginAsCustomer: "loginAsCustomer",
    customerBalanceAuditDataTab: "customerBalanceAuditDataTab",
    customerVouchersDataTab: "customerVouchersDataTab",
    voucherId: "voucherId",
    userId: "userId",
    voucherType: "voucherType",
    voucherValue: "voucherValue",
    voucherPercent: "voucherPercent",
    paymentAmount: "paymentAmount",
    minimumValue: "minimumValue",
    maximumValue: "maximumValue",
    useLimit: "useLimit",
    isCumulative: "isCumulative",
    includeTobacco: "includeTobacco",
    includeAlcohol: "includeAlcohol",
    freeDelivery: "freeDelivery",
    isReminding: "isReminding",
    isSending: "isSending",
    customerReviewsDataTab: "customerReviewsDataTab",
    orderWithId: "orderWithId",
    reviewedAt: "reviewedAt",
    recommendation: "recommendation",
    hasQualityIssue: "hasQualityIssue",
    hasComplienceIssue: "hasComplienceIssue",
    hasPunctualityIssue: "hasPunctualityIssue",
    hasPaymentIssue: "hasPaymentIssue",
    hasPageIssue: "hasPageIssue",
    customerMembershipTab: "customerMembershipTab",
    orderId: "orderId",
    awardedAt: "awardedAt",
    expiresAt: "expiresAt",
    qualityIssue: "qualityIssue",
    complienceIssue: "complienceIssue",
    punctualityIssue: "punctualityIssue",
    paymentIssue: "paymentIssue",
    pageIssue: "pageIssue",
    generalIssue: "generalIssue",
    whatDoYouLike: "whatDoYouLike",
    generalSuggestions: "generalSuggestions",
    offerSuggestions: "offerSuggestions",
    balanceCorrectionTab: "balanceCorrectionTab",
    removeBalanceCorrectionQuestion: "removeBalanceCorrectionQuestion",
    removeBalanceCorrection: "removeBalanceCorrection",
    addBalanceCorrection: "addBalanceCorrection",
    status: "status",
    changeAccountType: "changeAccountType",
    changeAccountTypeQuestion: "changeAccountTypeQuestion",
    creditAmount: "creditAmount",
    paymentTerm: "paymentTerm",
    defferedPaymentSettings: "defferedPaymentSettings",
    addEffect: "addEffect",
    addProgressive: "addProgressive",
    brands: "brands",
    cartActivationLimit: "cartActivationLimit",
    cartEffectType: "cartEffectType",
    categories: "categories",
    categoryIds: "categoryIds",
    customerId: "customerId",
    dateFrom: "dateFrom",
    dateTo: "dateTo",
    deliveryMethods: "deliveryMethods",
    discount: "discount",
    effectType: "effectType",
    excludedSegments: "excludedSegments",
    haveSegmentsConstraint: "haveSegmentsConstraint",
    hourFrom: "hourFrom",
    hourTo: "hourTo",
    leadPeriod: "leadPeriod",
    includedSegments: "includedSegments",
    invoicedOrderCountFrom: "invoicedOrderCountFrom",
    invoicedOrderCountTo: "invoicedOrderCountTo",
    isHidden: "isHidden",
    lastDeliveryDaysAgoFrom: "lastDeliveryDaysAgoFrom",
    lastDeliveryDaysAgoTo: "lastDeliveryDaysAgoTo",
    onlyIdentityVerifiedFirstOrder: "onlyIdentityVerifiedFirstOrder",
    orderRequirements: "orderRequirements",
    personalTarget: "personalTarget",
    segmentRequirements: "segmentRequirements",
    payment: "payment",
    placedOrderCountFrom: "placedOrderCountFrom",
    placedOrderCountTo: "placedOrderCountTo",
    poolEffectMode: "poolEffectMode",
    price: "price",
    priceEffectType: "priceEffectType",
    priceModifierType: "priceModifierType",
    producers: "producers",
    productIds: "productIds",
    promotionActivationLimit: "promotionActivationLimit",
    promotionType: "promotionType",
    quantity: "quantity",
    removeEffect: "removeEffect",
    removeProgressive: "removeProgressive",
    requiredQuantity: "requiredQuantity",
    requiredValue: "requiredValue",
    requiresConsent: "requiresConsent",
    userActivationLimit: "userActivationLimit",
    userActivationLimitExpirationPeriod: "userActivationLimitExpirationPeriod",
    voucherPromotions: "voucherPromotions",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    progressive: "progressive",
    deliveryDiscount: "deliveryDiscount",
    newDeliveryCost: "newDeliveryCost",
    Membership: "Membership",
    Cart: "Cart",
    Pool: "Pool",
    AdditonalProduct: "AdditonalProduct",
    SmapleProduct: "SmapleProduct",
    Delivery: "Delivery",
    Price: "Price",
    bonusPoint: "bonusPoint",
    monetaryPoints: "monetaryPoints",
    retencyPoints: "retencyPoints",
    newValue: "newValue",
    priceModification: "priceModification",
    discountPercent: "discountPercent",
    discountValue: "discountValue",
    newPrice: "newPrice",
    deliveryDates: "deliveryDates",
    currentDates: "currentDates",
    closingDates: "closingDates",
    isNotyfing: "isNotyfing",
    addDeliveryConditions: "addDeliveryConditions",
    removeDeliveryConditions: "removeDeliveryConditions",
    voucherDeliveryItems: "voucherDeliveryItems",
    addPool: "addPool",
    includeProducts: "includeProducts",
    excludeProducts: "excludeProducts",
    pool: "pool",
    removePool: "removePool",
    deliveryConditions: "deliveryConditions",
    segment: "segment",
    segments: "segments",
    requirments: "requirments",
    postcodes: "postcodes",
    addSection: "addSection",
    van: "van",
    dpd: "dpd",
    coolmat: "coolmat",
    deliveryWindows: "deliveryWindows",
    hour: "hour",
    reservationLimit: "reservationLimit",
    newLimit: "newLimit",
    vipLimit: "vipLimit",
    limitTotal: "limitTotal",
    reservationCount: "reservationCount",
    newReservationCoun: "newReservationCoun",
    vipReservationCount: "vipReservationCount",
    reservationTotal: "reservationTotal",
    orderCount: "orderCount",
    availablePaymentMethods: "availablePaymentMethods",
    mask: "mask",
    range: "range",
    rangeFrom: "rangeFrom",
    rangeTo: "rangeTo",
    deliveryCosts: "deliveryCosts",
    ranges: "ranges",
    from: "from",
    deliveryCost: "deliveryCost",
    marginOver: "marginOver",
    isAvailable: "isAvailable",
    isAlcohol: "isAlcohol",
    isTobacco: "isTobacco",
    isPromotionOnly: "isPromotionOnly",
    hasImage: "hasImage",
    isPrimaryVariant: "isPrimaryVariant",
    useHttps: "useHttps",
    suppressCategoryRendering: "suppressCategoryRendering",
    languages: "languages",
    feedFormat: "feedFormat",
    productUrlPrefix: "productUrlPrefix",
    isWater: "isWater",
    urlParams: "urlParams",
    isPositioned: "isPositioned",
    isPromoted: "isPromoted",
    slug: "slug",
    poolModifier: "poolModifier",
    cartModifier: "cartModifier",
    newProduct: "newProduct",
    sampleProduct: "sampleProduct",
    packagingModifier: "packagingModifier",
    deliveryModifier: "deliveryModifier",
    discountModifier: "discountModifier",
    Gratis: "Gratis",
    Discount: "Discount",
    Pack: "Pack",
    Pair: "Pair",
    Progressive: "Progressive",
    Sampling: "Sampling",
    DoublePrice: "DoublePrice",
    Packaging: "Packaging",
    GratisPicker: "GratisPicker",
    effect: "effect",
    modifier: "modifier",
    isProductLimitsDisabled: "isProductLimitsDisabled",
    isWaterLimitsDisabled: "isWaterLimitsDisabled",
    isPriceReplaced: "isPriceReplaced",
    isPriceLocked: "isPriceLocked",
    phrases: "phrases",
    pl: "pl",
    en: "en",
    generators: "generators",
    tags: "tags",
    codeLength: "codeLength",
    voucherCount: "voucherCount",
    batches: "batches",
    profileTypes: "profileTypes",
    addNew: "addNew",
    sectionName: "sectionName",
    sections: "sections",
    waterLimit: "waterLimit",
    OPEN: "OPEN",
    CLOSED: "CLOSED",
    ERROR: "ERROR",
    CORRECTED: "CORRECTED",
    Open: "Open",
    Placed: "Placed",
    Ready: "Ready",
    Invoiced: "Invoiced",
    Archived: "Archived",
    Cancelled: "Cancelled",
    Aborted: "Aborted",
    Completed: "Completed",
    Pending: "Pending",
    invoices: "invoices",
    invoice: "invoice",
    complaintEdit: "complaintEdit",
    complaintCreate: "complaintCreate",
    cancelOrder: "cancelOrder",
    cancelOrderSilently: "cancelOrderSilently",
    abortOrder: "abortOrder",
    orderCancelation: "orderCancelation",
    orderActions: "orderActions",
    generate: "generate",
    downloadBatch: "downloadBatch",
    orders: "orders",
    lines: "lines",
    quantityStatistic: "quantityStatistic",
    carriers: "carriers",
    totals: "totals",
    normal: "normal",
    cooler: "cooler",
    freezer: "freezer",
    Normal: "Normal",
    Cooler: "Cooler",
    Freezer: "Freezer",
    deliveryWindowsSummary: "deliveryWindowsSummary",
    amountFrisco: "amountFrisco",
    amountF24: "amountF24",
    merchant: "merchant",
    isVerificationRequired: "isVerificationRequired",
    isRepeatable: "isRepeatable",
    Generated: "Generated",
    Created: "Created",
    Generating: "Generating",
    F24: "F24",
    FRS: "FRS",
    rations: "rations",
    merchants: "merchants",
    productLimit: "productLimit",
    rules: "rules",
    optionalFrom: "optionalFrom",
    obligatoryFrom: "obligatoryFrom",
    imageSize: "imageSize",
    f24: "f24",
    frs: "frs",
    keywordsForm: "keywordsForm",
    generatorDefinition: "generatorDefinition",
    boosterPhraseDialogTitle: "boosterPhraseDialogTitle",
    cancel: "cancel",
    synonyms: "synonyms",
    dashboard: "dashboard",
    complaintLastWeekChartTitle: "complaintLastWeekChartTitle",
    complaintTotalChartTitle: "complaintTotalChartTitle",
    suppliers: "suppliers",
    gratisEffect: "gratisEffect",
    visableInBasket: "visableInBasket",
    hasAnswers: "hasAnswers",
    hasAttachments: "hasAttachments",
    generatorId: "generatorId",
    productTags: "productTags",
    documentNumber: "documentNumber",
    complaintNumber: "complaintNumber",
    documentCorrectionOperationId: "documentCorrectionOperationId",
    content: "content",
    vouchers: "vouchers",
    warning: "warning",
    previousComplaintWarning: "previousComplaintWarning",
    close: "close",
    processedAt: "processedAt",
    generateOnAddedSegments: "generateOnAddedSegments",
    validForDays: "validForDays",
    whenValidToAndValidFromNotEmpty: "whenValidToAndValidFromNotEmpty",
    addProduct: "addProduct",
    addPaperPacking: "addPaperPacking",
    addPlasicPacking: "addPlasicPacking",
    deliveryCostUpsell: "deliveryCostUpsell",
    reservationExpiresAt: "reservationExpiresAt",
    shippingAddress: "shippingAddress",
    deliveryWindow: "deliveryWindow",
    ExpireReservation: "ExpireReservation",
    ExpireSavedReservation: "ExpireSavedReservation",
    MakeReservationInternal: "MakeReservationInternal",
    PlaceOrder: "PlaceOrder",
    RemindSavedReservation: "RemindSavedReservation",
    RemindReservation: "RemindReservation",
    ExchangeSavedReservation: "ExchangeSavedReservation",
    ExchangeReservation: "ExchangeReservation",
    CancelReservation: "CancelReservation",
    CancelSavedReservation: "CancelSavedReservation",
    CancelReservationByToken: "CancelReservationByToken",
    ClearCart: "ClearCart",
    RequestReservationConfirmation: "RequestReservationConfirmation",
    EditOrder: "EditOrder",
    brand: "brand",
    subbrand: "subbrand",
    supplier: "supplier",
    producer: "producer",
    grammage: "grammage",
    packSize: "packSize",
    namePl: "namePl",
    nameEn: "nameEn",
    description: "description",
    ean: "ean",
    categoryId: "categoryId",
    depth: "depth",
    productVariants: "productVariants",
    productUrl: "productUrl",
    imageUrl: "imageUrl",
    restrictionId: "restrictionId",
    contentUrl: "contentUrl",
    productBase: "productBase",
    isWithdrawn: "isWithdrawn",
    isStocked: "isStocked",
    stock: "stock",
    reservedStock: "reservedStock",
    actualStock: "actualStock",
    isMultipackOnly: "isMultipackOnly",
    priceAfterDiscount: "priceAfterDiscount",
    priceBeforeDiscount: "priceBeforeDiscount",
    discountCampaignName: "discountCampaignName",
    vat: "vat",
    fromDateTime: "fromDateTime",
    activationCount: "activationCount",
    lastActivatedAt: "lastActivatedAt",
    Done: "Done",
    driverName: "driverName",
    deliveryComplaint: "deliveryComplaint",
    removeDeliveryComplaint: "removeDeliveryComplaint",
    removeDeliveryComplaintQuestion: "removeDeliveryComplaintQuestion",
    paymentId: "paymentId",
    channelName: "channelName",
    paymentMethod: "paymentMethod",
    externalPaymentId: "externalPaymentId",
    amount: "amount",
    refunded: "refunded",
    remaining: "remaining",
    refundAmount: "refundAmount",
    refundQuestion: "refundQuestion",
    refundId: "refundId",
    paymentRefund: "paymentRefund",
    toCustomer: "toCustomer",
    acceptPayment: "acceptPayment",
    transferPayment: "transferPayment",
    transferFor: "transferFor",
    title: "title",
    useCount: "useCount",
    activateUser: "activateUser",
    toDeactivateUser: "toDeactivateUser",
    deactivateUser: "deactivateUser",
    toActivateUser: "toActivateUser",
    placedOrderCountModulo: "placedOrderCountModulo",
    placedOrderCountModuloRemainder: "placedOrderCountModuloRemainder",
    invoicedOrderCountModulo: "invoicedOrderCountModulo",
    invoicedOrderCountModuloRemainder: "invoicedOrderCountModuloRemainder",
    membershipOrderCountModulo: "membershipOrderCountModulo",
    membershipOrderCountModuloRemainder: "membershipOrderCountModuloRemainder",
    Daily: "Daily",
    Weekly: "Weekly",
    Monthly: "Monthly",
    Quarterly: "Quarterly",
    Yearly: "Yearly",
    userActivationLimitExpirationInterval: "userActivationLimitExpirationInterval",
    membershipOrderCountFrom: "membershipOrderCountFrom",
    membershipOrderCountTo: "membershipOrderCountTo",
    chosenProductModifier: "chosenProductModifier",
    discountLink: "discountLink",
    includedChannel: "includedChannel",
    excludedChannel: "excludedChannel",
    packagingMethods: "packagingMethods",
    purchasedProducts: "purchasedProducts",
    unpurchasedProducts: "unpurchasedProducts",
    choiceRequirements: "choiceRequirements",
    retentionRequirements: "retentionRequirements",
    awardRequirements: "awardRequirements",
    maximumAvailableChoiceCount: "maximumAvailableChoiceCount",
    gifts: "gifts",
    giftChoices: "giftChoices",
    lastReservedAt: "lastReservedAt",
    businessRegistryNumber: "businessRegistryNumber",
    costReasons: "costReasons",
    costReasonList: "costReasonList",
    costReasonForm: "costReasonForm",
    subsidiaryGainForm: "subsidiaryGainForm",
    toRemove: "toRemove",
    temponarySave: "temponarySave",
    saveToVerification: "saveToVerification",
    contractForm: "contractForm",
    accept: "accept",
    reject: "reject",
    correct: "correct",
    undo: "undo",
    fileDragAndDropLabel: "fileDragAndDropLabel",
    vendor: "vendor",
    users: "users",
    orderNumber: "orderNumber",
    orderCreatedAt: "orderCreatedAt",
    vendorId: "vendorId",
    deliveryAt: "deliveryAt",
    deliveryAddress: "deliveryAddress",
    invoiceNumber: "invoiceNumber",
    correctionNumbers: "correctionNumbers",
    vendorOrderView: "vendorOrderView",
    deliveredQuantity: "deliveredQuantity",
    invoiceCorrect: "invoiceCorrect",
    update: "update",
    user: "user",
    jobPosition: "jobPosition",
    invoiceIssueReason: "invoiceIssueReason",
    dotPayPayouts: "dotPayPayouts",
    payoutNumber: "payoutNumber",
    payoutDate: "payoutDate",
    payoutAmount: "payoutAmount",
    payoutIsValid: "payoutIsValid",
    fileImportedAt: "fileImportedAt",
    fileAmount: "fileAmount",
    systemReadonlyForm: "systemReadonlyForm",
    ActiveMembership: "ActiveMembership",
    NoMembership: "NoMembership",
    InactiveMembership: "InactiveMembership",
    membershipStatus: "membershipStatus",
    productPreviewSearch: "productPreviewSearch",
    documentCategory: "documentCategory",
    documentType: "documentType",
    scanDateFrom: "scanDateFrom",
    scanDateTo: "scanDateTo",
    documentText: "documentText",
    removeItem: "removeItem",
    toRemoveItem: "toRemoveItem",
    preview: "preview",
    delete: "delete",
    link: "link",
    menu: "menu",
    copy: "copy",
    retry: "retry",
    list: "list",
    cnStakes: "cnStakes",
    cnStake: "cnStake",
    shopName: "shopName",
    shopUrl: "shopUrl",
    useApi: "useApi",
    apiUrl: "apiUrl",
    hasUser: "hasUser",
    userName: "userName",
    shopConfiguration: "shopConfiguration",
    isDisabled: "isDisabled",
    sessionLengthInMinutes: "sessionLengthInMinutes",
    priceBeforePromotion: "priceBeforePromotion",
    friscoId: "friscoId",
    externalId: "externalId",
    unitOfMeasure: "unitOfMeasure",
    promotionDateTo: "promotionDateTo",
    data: "data",
    shopInfo: "shopInfo",
    basicInfo: "basicInfo",
    scan: "scan",
    scanFrom: "scanFrom",
    isEnabled: "isEnabled",
    shopExternalId: "shopExternalId",
    externalProduct: "externalProduct",
    noData: "noData",
    activeChoiceGroup: "activeChoiceGroup",
    activeChoiceGroupTooltip: "activeChoiceGroupTooltip",
    appliesToAllProducts: "appliesToAllProducts",
    document: "document",
    documentData: "documentData",
    download: "download",
    documentDate: "documentDate",
    scanDate: "scanDate",
    documentStatus: "documentStatus",
    documentContent: "documentContent",
    documentArchive: "documentArchive",
    roles: "roles",
    documentPreview: "documentPreview",
    permissions: "permissions",
    noOptions: "noOptions",
    access: "access",
    accessBy: "accessBy",
    division: "division",
    divisions: "divisions",
    refundationForm: "refundationForm",
    to: "to",
    downloadProducts: "downloadProducts",
    isPerWarehouse: "isPerWarehouse",
    explicitDeliveryCost: "explicitDeliveryCost",
    explicitPackagingCost: "explicitPackagingCost",
    warehouse: "warehouse",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    Q1: "Q1",
    Q2: "Q2",
    Q3: "Q3",
    Q4: "Q4",
    H1: "H1",
    H2: "H2",
    Year: "Year",
    threshold: "threshold",
    company: "company",
    creditLimitForm: "creditLimitForm",
    levelOfConfidentiality: "levelOfConfidentiality",
    barcode: "barcode",
    externalDocumentNumber: "externalDocumentNumber",
    segmentSuffix: "segmentSuffix",
    expirationPeriod: "expirationPeriod",
    expiringSegment: "expiringSegment",
    exportSection: "exportSection",
    importSection: "importSection",
    exportSections: "exportSections",
    exportProducts: "exportProducts",
    removeSection: "removeSection",
    clearProductList: "clearProductList",
    doYouWantToClearProductList: "doYouWantToClearProductList",
    complaintId: "complaintId",
    allowedEmailHosts: "allowedEmailHosts",
    isFallback: "isFallback",
    inActive: "inActive",
    all: "all",
    reload: "reload",
    loading: "loading",
    somethingWentWrong: "somethingWentWrong",
    emptySelectItem: "emptySelectItem",
    definition: "definition",
    validationError: "validationError",
    invalidRecipients: "invalidRecipients",
    muteCustomerDebt: "muteCustomerDebt",
    shouldMuteCustomerDebt: "shouldMuteCustomerDebt",
    reviews: "reviews",
    review: "review",
    Accept: "Accept",
    Reject: "Reject",
    init: "init",
    VATIN: "VATIN",
    addresses: "addresses",
    Suppliers: "Suppliers",
    Vendor: "Vendor",
    Dry: "Dry",
    popularity: "popularity",
    margin: "margin",
    soldAt: "soldAt",
    width: "width",
    productDepth: "productDepth",
    weight: "weight",
    height: "height",
    IsRejected: "IsRejected",
    Reviewer: "Reviewer",
    sendToReview: "sendToReview",
    onlyOneCodePerTree: "onlyOneCodePerTree",
    categoryTree: "categoryTree",
    code: "code",
    codesInTrees: "codesInTrees",
    Comment: "Comment",
    Download: "Download",
    fileName: "fileName",
    State: "State",
    Fill: "Fill",
    Accepted: "Accepted",
    Rejected: "Rejected",
    Trade: "Trade",
    Auditor: "Auditor",
    Inited: "Inited",
    Filled: "Filled",
    Audited: "Audited",
    Sent: "Sent",
    ToCorrect: "ToCorrect",
    ReviewedByTrade: "ReviewedByTrade",
    RejectedByAuditor: "RejectedByAuditor",
    RejectedByTrade: "RejectedByTrade",
    UpdateInited: "UpdateInited",
    Updated: "Updated",
    ProductIntegrationAutoChanged: "ProductIntegrationAutoChanged",
    DeliveryCosts: "DeliveryCosts",
    PostCodes: "PostCodes",
    Integration: "Integration",
    None: "None",
    Merchant: "Merchant",
    fullValidityTerm: "fullValidityTerm",
    minimumValidityTerm: "minimumValidityTerm",
    alcoholByVolume: "alcoholByVolume",
    Variant: "Variant",
    eans: "eans",
    saving: "saving",
    ProductCreated: "ProductCreated",
    Timestamp: "Timestamp",
    Edit: "Edit",
    Title: "Title",
    Description: "Description",
    Roles: "Roles",
    Add: "Add",
    Loading: "Loading",
    Logout: "Logout",
    isParcelDelivery: "isParcelDelivery",
    isVanDelivery: "isVanDelivery",
    isPickupDelivery: "isPickupDelivery",
    isVariableWeight: "isVariableWeight",
    ID: "ID",
    category: "category",
    Change: "Change",
    Remove: "Remove",
    create: "create",
    Update: "Update",
    Producer: "Producer",
    Supplier: "Supplier",
    analyticGroup: "analyticGroup",
    storageType: "storageType",
    Filter: "Filter",
    Brand: "Brand",
    Subbrand: "Subbrand",
    Grammage: "Grammage",
    claims: "claims",
    Password: "Password",
    Name: "Name",
    of: "of",
    error: "error",
    ok: "ok",
    calendar: "calendar",
    PaymentTransferBzwbk: "PaymentTransferBzwbk",
    PaymentTransferMillenium: "PaymentTransferMillenium",
    PaymentTransferMBank: "PaymentTransferMBank",
    Warehouse: "Warehouse",
    FV: "FV",
    Invoices: "Invoices",
    COR: "COR",
    complaintsDashboardButton: "complaintsDashboardButton",
    archiveDashboardButton: "archiveDashboardButton",
    archiveErrorsCount: "archiveErrorsCount",
    archiveErrors: "archiveErrors",
    operationProcessingTime: "operationProcessingTime",
    priceListsDashboard: "priceListsDashboard",
    productClassification: "productClassification",
    pricePoint: "pricePoint",
    generateOnRegistration: "generateOnRegistration",
    generateOnTokenRefresh: "generateOnTokenRefresh",
    batchStatus: "batchStatus",
    message: "message",
    takeAction: "takeAction",
    editAction: "editAction",
    balanceDebt: "balanceDebt",
    takenAt: "takenAt",
    nextActionAt: "nextActionAt",
    activityReason: "activityReason",
    BusinessSupportInvolved: "BusinessSupportInvolved",
    CourtCaseOpened: "CourtCaseOpened",
    DebtCollectionCompanyInvolved: "DebtCollectionCompanyInvolved",
    EmailContact: "EmailContact",
    InstallmentPlanCreated: "InstallmentPlanCreated",
    InVerification: "InVerification",
    PaymentRequestDocumentSend: "PaymentRequestDocumentSend",
    PhoneContact: "PhoneContact",
    PostponeContact: "PostponeContact",
    SmsContact: "SmsContact",
    BalancePaid: "BalancePaid",
    BalancePaymentApproved: "BalancePaymentApproved",
    BalancePaymentCreated: "BalancePaymentCreated",
    BalancePaymentTransferred: "BalancePaymentTransferred",
    BalanceReseted: "BalanceReseted",
    ComplaintCanceled: "ComplaintCanceled",
    ComplaintCreated: "ComplaintCreated",
    ComplaintUpdated: "ComplaintUpdated",
    CreditLimitChanged: "CreditLimitChanged",
    DeliveryStatementCharged: "DeliveryStatementCharged",
    DocumentCorrectionCreated: "DocumentCorrectionCreated",
    OnlinePaymentCompleted: "OnlinePaymentCompleted",
    OnlinePaymentImported: "OnlinePaymentImported",
    OrderAborted: "OrderAborted",
    OrderCanceled: "OrderCanceled",
    OrderChanged: "OrderChanged",
    OrderDelivered: "OrderDelivered",
    OrderInvoiced: "OrderInvoiced",
    OrderPlaced: "OrderPlaced",
    OrderTransferred: "OrderTransferred",
    OrderTransferredWithUnpaidBalance: "OrderTransferredWithUnpaidBalance",
    OrderUnaborted: "OrderUnaborted",
    PaymentOverpayed: "PaymentOverpayed",
    PaymentRefunded: "PaymentRefunded",
    RefundCanceled: "RefundCanceled",
    Muted: "Muted",
    summary: "summary",
    timeDays: "timeDays",
    balanceDebts: "balanceDebts",
    manualActivityCounter: "manualActivityCounter",
    productProblems: "productProblems",
    discountValidation: "discountValidation",
    LowPriceBelowDiscountPrice: "LowPriceBelowDiscountPrice",
    LowPriceBelowPromotionPrice: "LowPriceBelowPromotionPrice",
    ReplacedPriceGreaterOrEqualToPrice: "ReplacedPriceGreaterOrEqualToPrice",
    IncludeInReportForLawyers: "IncludeInReportForLawyers",
    ReportedToLawyers: "ReportedToLawyers",
    hasCreditLimit: "hasCreditLimit",
    cartProducts: "cartProducts",
    purchasedProductsHeader: "purchasedProductsHeader",
    MaximumPoolProductCountExceeded: "MaximumPoolProductCountExceeded",
    priceAfterVoucher: "priceAfterVoucher",
    newPriceVsLowPrice: "newPriceVsLowPrice",
    poolCountVsMaxPoolCount: "poolCountVsMaxPoolCount",
    cashbackPercent: "cashbackPercent",
    promotionPeriod: "promotionPeriod",
    promotionPeriods: "promotionPeriods",
    marketingTool: "marketingTool",
    marketingTools: "marketingTools",
    toolkit: "toolkit",
    toolkits: "toolkits",
    reservationKit: "reservationKit",
    reservationKits: "reservationKits",
    exportAll: "exportAll",
    isDate: "isDate",
    usersCount: "usersCount",
    dynamicSegments: "dynamicSegments",
    dynamicSegment: "dynamicSegment",
    dynamicSegmentTooltip: "dynamicSegmentTooltip",
    cashbackLimit: "cashbackLimit",
    procedures: "procedures",
    procedure: "procedure",
    version: "version",
    isForEveryone: "isForEveryone",
    departments: "departments",
    postponedDate: "postponedDate",
    file: "file",
    lastAccept: "lastAccept",
    lastPostpone: "lastPostpone",
    documents: "documents",
    groups: "groups",
    employees: "employees",
    assigned: "assigned",
    requiresDecision: "requiresDecision",
    noVendorsForMerchant: "noVendorsForMerchant",
    selectCompanyFirst: "selectCompanyFirst",
    emails: "emails",
    emailForm: "emailForm",
    subject: "subject",
    isSended: "isSended",
    body: "body",
    attachment: "attachment",
    import: "import",
    importRecipientsFromFile: "importRecipientsFromFile",
    sendDate: "sendDate",
    productInternal: "productInternal",
    priceHistory: "priceHistory",
    lowPrice: "lowPrice",
    problems: "problems",
    problem: "problem",
    changeTime: "changeTime",
    PackGratis: "PackGratis",
    PoolGratis: "PoolGratis",
    ChosenProduct: "ChosenProduct",
    Segment: "Segment",
    PoolUnavailable: "PoolUnavailable",
    NewProductHasProblems: "NewProductHasProblems",
    NewProductUnavailable: "NewProductUnavailable",
    NewProductSampleOnly: "NewProductSampleOnly",
    NewProductNotFound: "NewProductNotFound",
    SampleProductHasProblems: "SampleProductHasProblems",
    SampleProductUnavailable: "SampleProductUnavailable",
    SampleProductNotFound: "SampleProductNotFound",
    MappedProductNotFound: "MappedProductNotFound",
    NewProductRequiresBillingVatin: "NewProductRequiresBillingVatin",
    SampleProductRequiresBillingVatin: "SampleProductRequiresBillingVatin",
    isGlobal: "isGlobal",
    purchasedOrdersAgoFrom: "purchasedOrdersAgoFrom",
    purchasedOrdersAgoTo: "purchasedOrdersAgoTo",
    targetAmountFrom: "targetAmountFrom",
    targetAmountTo: "targetAmountTo",
    minumumChoiceCount: "minumumChoiceCount",
    maximumChoiceCount: "maximumChoiceCount",
    fallbackChoiceCount: "fallbackChoiceCount",
    giftForm: "giftForm",
    reservationMonthlyLimit: "reservationMonthlyLimit",
    giftChoiceValidation: "giftChoiceValidation",
    rewards: "rewards",
    reward: "reward",
    isDisallowed: "isDisallowed",
    isUserDisallowed: "isUserDisallowed",
    smsCode: "smsCode",
    shopAuthorization: "shopAuthorization",
    downloadTemplate: "downloadTemplate",
    closedAccountingPeriods: "closedAccountingPeriods",
    month: "month",
    year: "year",
    closingDate: "closingDate",
    accountingPeriod: "accountingPeriod",
    accountingPeriodClosingSoon: "accountingPeriodClosingSoon",
    accountingPeriodOverridePeriod: "accountingPeriodOverridePeriod",
    accountingPeriodClosed: "accountingPeriodClosed",
    country: "country",
    currency: "currency",
    promotionValidation: "promotionValidation",
    contactType: "contactType",
    changedPostfix: "changedPostfix",
    deletedPostfix: "deletedPostfix",
    addedPostfix: "addedPostfix",
    removed: "removed",
    noDiff: "noDiff",
    changed: "changed",
    added: "added",
    noValue: "noValue",
    linkedDocuments: "linkedDocuments",
    archiveDocuments: "archiveDocuments",
    documentDefinition: "documentDefinition",
    documentDefinitions: "documentDefinitions",
    vatCorrection: "vatCorrection",
    vatCorrectionsImport: "vatCorrectionsImport",
    processingInProgress: "processingInProgress",
    completedUnsuccessfully: "completedUnsuccessfully",
    completedSuccessfully: "completedSuccessfully",
    importFile: "importFile",
    errors: "errors",
    row: "row",
    vatCorrectionBatches: "vatCorrectionBatches",
    correctionCount: "correctionCount",
    openCount: "openCount",
    errorCount: "errorCount",
    cancelledCount: "cancelledCount",
    correctedCount: "correctedCount",
    deferredPayment: "deferredPayment",
    deferredPaymentSettings: "deferredPaymentSettings",
    deferredPaymentRemove: "deferredPaymentRemove",
    isUnrestrictedStockDisallowed: "isUnrestrictedStockDisallowed",
    priceBeforeFirstDecrease: "priceBeforeFirstDecrease",
    priceAfterPromotion: "priceAfterPromotion",
    lastScanDate: "lastScanDate",
    creditLimit: "creditLimit",
    validateFile: "validateFile",
    correctionRequest: "correctionRequest",
    getDocumentNumbers: "getDocumentNumbers",
    saveFiles: "saveFiles",
    logIn: "logIn",
    documentDefinitionWaitingForDecisionText: "documentDefinitionWaitingForDecisionText",
    sharedGroup: "sharedGroup",
    select: "select",
    rejectedPromotions: "rejectedPromotions",
    warehouses: "warehouses",
    CampaignNameNotIgnoredByPriceHistory: "CampaignNameNotIgnoredByPriceHistory",
    productSubstitutes: "productSubstitutes",
    productSubstitute: "productSubstitute",
    useSearchEan: "useSearchEan",
    responsibleUser: "responsibleUser",
    downloadReportFile: "downloadReportFile",
    withhold: "withhold",
    withholdPayment: "withholdPayment",
    BalancePaymentWithheld: "BalancePaymentWithheld",
    isVatStatement: "isVatStatement",
    subsidiaryGain: "subsidiaryGain",
    subsidiaryGains: "subsidiaryGains",
    categoryUseLimit: "categoryUseLimit",
    isLimitForCategory: "isLimitForCategory",
    seoReport: "seoReport",
    createdFrom: "createdFrom",
    role: "role",
    departmentStructure: "departmentStructure",
    procedureCategory: "procedureCategory",
    validAt: "validAt",
    clearText: "clearText",
    postpone: "postpone",
    unaccepted: "unaccepted",
    needsAcceptance: "needsAcceptance",
    dedicatedForDepartment: "dedicatedForDepartment",
    companyStructure: "companyStructure",
    replacementDiscountLink: "replacementDiscountLink",
    priceBeforeReplacement: "priceBeforeReplacement",
    b2c: "b2c",
    b2b: "b2b",
    alcohol: "alcohol",
    lowSeasonPrice: "lowSeasonPrice",
    highSeasonPrice: "highSeasonPrice",
    defaultLength: "defaultLength",
    priceListsNotValid: "priceListsNotValid",
    isStandardPrice: "isStandardPrice",
    standardPriceTooltip: "standardPriceTooltip",
    customerContact: "customerContact",
    customerContactType: "customerContactType",
    linkOff: "linkOff",
    clearForm: "clearForm",
    subjects: "subjects",
    marketingBudgetForm: "marketingBudgetForm",
    catmansBudget: "catmansBudget",
    catman: "catman",
    WaitingForDecision: "WaitingForDecision",
    Total: "Total",
    form: "form",
    catmanBudgetChange: "catmanBudgetChange",
    allWithFilterText: "allWithFilterText",
    selectStatus: "selectStatus",
    linkDocument: "linkDocument",
    unlinkDocument: "unlinkDocument",
    deviceType: "deviceType",
    deviceTypes: "deviceTypes",
    devicesList: "devicesList",
    assetNumber: "assetNumber",
    friscoNumber: "friscoNumber",
    isWithdrown: "isWithdrown",
    device: "device",
    serialNumber: "serialNumber",
    invoiceDate: "invoiceDate",
    Draft: "Draft",
    ForSignature: "ForSignature",
    devicesAssignmentList: "devicesAssignmentList",
    employeeAssignedDevices: "employeeAssignedDevices",
    assignAndReturnHistory: "assignAndReturnHistory",
    assignedDevices: "assignedDevices",
    returnedDevices: "returnedDevices",
    date: "date",
    employee: "employee",
    workstation: "workstation",
    assignmentType: "assignmentType",
    Assignment: "Assignment",
    Return: "Return",
    assignOrReturn: "assignOrReturn",
    devices: "devices",
    readyToSign: "readyToSign",
    sign: "sign",
    signature: "signature",
    unfinishedAssignments: "unfinishedAssignments",
    removeFormWithDate: "removeFormWithDate",
    workplace: "workplace",
    workstationNumber: "workstationNumber",
    workstationAssignedDevices: "workstationAssignedDevices",
    confirm: "confirm",
    workstationsList: "workstationsList",
    assingAssetNumbers: "assingAssetNumbers",
    unassigned: "unassigned",
    editable: "editable",
    assignDevice: "assignDevice",
    isAssigned: "isAssigned",
    generatorActivation: "generatorActivation",
    isFriscoNumberRequired: "isFriscoNumberRequired",
    importRetentionManagers: "importRetentionManagers",
    importUserBalance: "importUserBalance",
    history: "history",
    sendWithoutSignature: "sendWithoutSignature",
    purchaseSchedulesList: "purchaseSchedulesList",
    purchaseRules: "purchaseRules",
    purchaseSchedule: "purchaseSchedule",
    earlyMorning: "earlyMorning",
    morning: "morning",
    lateMorning: "lateMorning",
    afternoon: "afternoon",
    sameDay: "sameDay",
    deliveryDateFrom: "deliveryDateFrom",
    deliveryDateTo: "deliveryDateTo",
    weekdays: "weekdays",
    shifts: "shifts",
    actionType: "actionType",
    deliveryDayOffset: "deliveryDayOffset",
    isProgressiveOrdersEnabled: "isProgressiveOrdersEnabled",
    dayOffset: "dayOffset",
    timeOffset: "timeOffset",
    removeFormDated: "removeFormDated",
    promotionCreatorDraftsList: "promotionCreatorDraftsList",
    promotionCreatorDraft: "promotionCreatorDraft",
    sold30DaysTotal: "sold30DaysTotal",
    lowestPrice30DaysAllWarehouses: "lowestPrice30DaysAllWarehouses",
    price100: "price100",
    basicDiscount: "basicDiscount",
    priceAfterBasicDiscount: "priceAfterBasicDiscount",
    promotionDiscount: "promotionDiscount",
    calculationType: "calculationType",
    promotionBuyingPrice: "promotionBuyingPrice",
    promotionBuyingPriceType: "promotionBuyingPriceType",
    promotionBuyingDateFrom: "promotionBuyingDateFrom",
    promotionBuyingDateTo: "promotionBuyingDateTo",
    regularPrice: "regularPrice",
    regularMargin: "regularMargin",
    clientPromotionPrice: "clientPromotionPrice",
    promotionMargin: "promotionMargin",
    retro: "retro",
    promotionMechanism: "promotionMechanism",
    refundationValue: "refundationValue",
    lastDaysPromotionMechanisms: "lastDaysPromotionMechanisms",
    amountSold30Days: "amountSold30Days",
    lowestPromoSpiderPrice: "lowestPromoSpiderPrice",
    lowestRegularSpiderPrice: "lowestRegularSpiderPrice",
    addRow: "addRow",
    createPromotion: "createPromotion",
    createRefundation: "createRefundation",
    setAsFinalVersion: "setAsFinalVersion",
    copyDataToDivisions: "copyDataToDivisions",
    promotionMechanismType: "promotionMechanismType",
    addManyProducts: "addManyProducts",
    areProductIds: "areProductIds",
    saveSuccess: "saveSuccess",
    saveFail: "saveFail",
    fillDatesAndProducts: "fillDatesAndProducts",
    valuesSeparateBy: "valuesSeparateBy",
    fillClientPromotionPrice: "fillClientPromotionPrice",
    productIdsValidation: "productIdsValidation",
    eansValidation: "eansValidation",
    loadingDataPleaseWait: "loadingDataPleaseWait",
    choosePromotionMechanism: "choosePromotionMechanism",
    fillManyProducts: "fillManyProducts",
    formCatman: "formCatman",
    multipleValuesByComma: "multipleValuesByComma",
    mustSelectSamePromotionMechanismType: "mustSelectSamePromotionMechanismType",
    goToForm: "goToForm",
    clearFilters: "clearFilters",
    onlyDoneItemsCheckable: "onlyDoneItemsCheckable",
    useTemplate: "useTemplate",
    deleteProductsFromDivisions: "deleteProductsFromDivisions",
    Plastic: "Plastic",
    Paper: "Paper",
    CashOnDelivery: "CashOnDelivery",
    CreditCardOnDelivery: "CreditCardOnDelivery",
    Bzwbk: "Bzwbk",
    DotPay: "DotPay",
    Millennium: "Millennium",
    MBank: "MBank",
    isEarlierMorningDelivery: "isEarlierMorningDelivery",
    isLateMorningDelivery: "isLateMorningDelivery",
    isAfternoonDelivery: "isAfternoonDelivery",
    isSameDayDelivery: "isSameDayDelivery",
    isNonTradeSunday: "isNonTradeSunday",
    isMondayAfterNonTradeSunday: "isMondayAfterNonTradeSunday",
    isMorningAfterHoliday: "isMorningAfterHoliday",
    purchasePredicate: "purchasePredicate",
    cartContextPredicate: "cartContextPredicate",
    notesAndBenefits: "notesAndBenefits",
    recipientForm: "recipientForm",
    recipientEmployees: "recipientEmployees",
    recipient: "recipient",
    employmentType: "employmentType",
    closeDate: "closeDate",
    charges: "charges",
    ammount: "ammount",
    recipientEmployee: "recipientEmployee",
    plateNumber: "plateNumber",
    chargesRegisterForm: "chargesRegisterForm",
    closeRegister: "closeRegister",
    selectFile: "selectFile",
    settlementPeriod: "settlementPeriod",
    dateOfEvent: "dateOfEvent",
    charge: "charge",
    benefitAmount: "benefitAmount",
    invoiceAmount: "invoiceAmount",
    additionalPerson: "additionalPerson",
    costInvoice: "costInvoice",
    merchandise: "merchandise",
    selectRowsToSave: "selectRowsToSave",
    closeForm: "closeForm",
    included: "included",
    selectAll: "selectAll",
    unselectAll: "unselectAll",
    chargeDocument: "chargeDocument",
    recipientAXId: "recipientAXId",
    open: "open",
    closed: "closed",
    carRegistrationNumber: "carRegistrationNumber",
    isReplacementCar: "isReplacementCar",
    owner: "owner",
    car: "car",
    cars: "cars",
    chargeKinds: "chargeKinds",
    chargeKind: "chargeKind",
    chargeType: "chargeType",
    isAdditionalPersonRequired: "isAdditionalPersonRequired",
    isBenefitAmountRequired: "isBenefitAmountRequired",
    isCostInvoiceRequired: "isCostInvoiceRequired",
    isInvoiceAmountRequired: "isInvoiceAmountRequired",
    isMerchandiseRequired: "isMerchandiseRequired",
    isOrderNumberRequired: "isOrderNumberRequired",
    isRegisterNumberRequired: "isRegisterNumberRequired",
    chargesRegisterHeaderExport: "chargesRegisterHeaderExport",
    chargesRegisterExport: "chargesRegisterExport",
    isAdditionalDescriptionRequired: "isAdditionalDescriptionRequired",
    benefits: "benefits",
    exitUnsavedForm: "exitUnsavedForm",
    differentLimitsPerDivision: "differentLimitsPerDivision",
    activationLimit: "activationLimit",
    divisionActivationCount: "divisionActivationCount",
    minimumTotalWithDeliveryCostOverride: "minimumTotalWithDeliveryCostOverride",
    isUnending: "isUnending",
    warehouseReservationLimits: "warehouseReservationLimits",
} as const;

export default common;
