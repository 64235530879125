import { makeStyles } from "tss-react/mui";
import React from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { common } from "translations";
import { Item, ValidationError } from "control-types";
import { dateToString } from "utils-ts/functions";
import { useTranslation } from "utils-ts/hooks";
import { FormHelperText } from "components-ts/controls";

const useStyles = makeStyles()((theme) => ({
    container: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
    },
    label: {
        color: theme.palette.primary.dark,
    },
}));

type TextWithLabelProps = {
    value: string[] | number[] | string | number | moment.Moment | Date | boolean | undefined;
    label?: string;
    adornment?: {
        position: "start" | "end";
        value: React.ReactNode;
    };
    numberType?: "decimal" | "numeric";
    multiple?: boolean;
    items?: Item[];
    error?: ValidationError;
    width?: number;
};

const TextWithLabel: React.FC<TextWithLabelProps> = ({ value, label, adornment, numberType, items, multiple, error, width }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    let val = value?.toString();
    const endAdornment = adornment != undefined && adornment.position === "end" ? adornment.value : "";
    const startAdornment = adornment != undefined && adornment.position === "start" ? adornment.value : "";
    if (moment.isMoment(value)) {
        val = value.format("YYYY-MM-DD HH:mm");
    } else if (moment.isDate(value)) {
        val = dateToString(value);
    } else if (items && items.length > 0) {
        const hasNames = items.some((i) => typeof i === "object" && "name" in i);
        if (multiple && Array.isArray(value)) {
            if (hasNames) {
                const selectedItem = items.find(
                    (i) => typeof i === "object" && "value" in i && value.some((s: string | number | undefined) => s === i.value)
                );
                if (typeof selectedItem === "object" && "name" in selectedItem) {
                    val = typeof selectedItem.name !== "string" ? t(selectedItem.name) : selectedItem.name;
                } else {
                    val = value.join(", ");
                }
            } else {
                val = value.join(", ");
            }
        } else {
            if (hasNames) {
                const selectedItem = items.find((i) => typeof i === "object" && "value" in i && i.value === value);
                if (typeof selectedItem === "object" && "name" in selectedItem) {
                    val = typeof selectedItem.name !== "string" ? t(selectedItem.name) : selectedItem.name;
                } else {
                    val = value?.toString();
                }
            } else {
                val = value?.toString();
            }
        }
    } else if (Boolean(value) === value) {
        if (Boolean(value)) {
            val = t(common.yes);
        } else {
            val = t(common.no);
        }
    }

    return (
        <Grid
            item
            key={label}
            className={classes.container}
        >
            {label && (
                <Typography
                    component="div"
                    className={classes.label}
                    variant="caption"
                    width={width}
                >
                    {label}
                </Typography>
            )}

            <Typography
                component="div"
                display="block"
                width={width}
            >
                {`${startAdornment}${
                    value || value === 0
                        ? numberType !== undefined
                            ? numberType === "decimal"
                                ? parseFloat(String(val)).toFixed(2)
                                : parseInt(String(val)).toString()
                            : val
                        : ""
                }${endAdornment}`}
            </Typography>

            <FormHelperText
                error={{
                    hasError: Boolean(error),
                    message: error?.message,
                }}
            />
        </Grid>
    );
};

export default TextWithLabel;
