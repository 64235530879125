import { makeStyles } from "tss-react/mui";
import { useEffect } from "react";
import React from "react";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import { Collapse, Grid, Paper, PaperProps, Theme, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Fab from "@mui/material/Fab";
import { Translation } from "translations/Translation";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IconButton } from "components-ts/controls";
import Spacing from "components-ts/view/spacing/Spacing";

type ViewProps = PaperProps & {
    headerText?: Translation;
    subheaderText?: Translation;
    useNavigation?: boolean;
    listPath?: string;
    isMainView?: boolean;
    customTitle?: string;
    customHeader?: React.ReactNode;
    useCollapse?: boolean;
    isCollapsed?: boolean;
    canSelectAll?: boolean;
    selectAllFunction?: (value: boolean) => void;
};

const useStyles = makeStyles()((theme: Theme) => ({
    headerPaper: {
        color: theme.palette.primary.contrastText,
        background: theme.palette.secondary.main,
        position: "relative",
        borderRadius: "5px",
        margin: "0px 10px",
        top: "-15px",
    },
}));

const View = ({
    children,
    headerText,
    subheaderText,
    useNavigation,
    listPath,
    isMainView = false,
    customTitle,
    customHeader,
    useCollapse = false,
    isCollapsed = true,
    canSelectAll = false,
    selectAllFunction,
    ...paperProps
}: ViewProps) => {
    const { push, goBack } = usePush();
    const { t } = useTranslation();
    const { classes } = useStyles();
    const [collapsed, toggleCollapsed] = React.useState(isCollapsed);
    useEffect(() => {
        if (isMainView) {
            document.title = headerText ? customTitle || t(headerText) : "Manager";
        }
    }, [headerText, customTitle]);

    return (
        <Paper
            {...paperProps}
            style={{
                marginTop: isMainView ? "25px" : "16px",
                width: "100%",
                ...paperProps.style,
            }}
        >
            <Paper
                elevation={4}
                className={classes.headerPaper}
            >
                <Grid
                    display="flex"
                    alignItems="center"
                >
                    <Grid
                        container
                        direction="row"
                        justify-content="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            {listPath || useNavigation ? (
                                <Spacing spacing={1}>
                                    <Fab
                                        onClick={() => {
                                            if (listPath) {
                                                push(listPath);
                                            } else {
                                                goBack();
                                            }
                                        }}
                                        size="small"
                                        color="primary"
                                    >
                                        <NavigateBefore />
                                    </Fab>
                                </Spacing>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item>
                            <Spacing spacing={1}>
                                <Grid
                                    container
                                    direction="row"
                                    justify-content="flex-start"
                                    alignItems="center"
                                >
                                    {selectAllFunction && (
                                        <Checkbox
                                            checked={!canSelectAll}
                                            onChange={(_, value) => selectAllFunction(value)}
                                            sx={{
                                                "color": "#fff",
                                                "&.Mui-checked": {
                                                    color: "#fff",
                                                },
                                            }}
                                        />
                                    )}
                                    <Typography
                                        variant={isMainView ? "h6" : "subtitle1"}
                                        style={{ textTransform: "uppercase" }}
                                    >
                                        {headerText && t(headerText)}
                                    </Typography>
                                </Grid>
                                <Typography variant="subtitle2">{subheaderText && t(subheaderText)}</Typography>
                            </Spacing>
                        </Grid>
                        {customHeader ?? <Grid item>{customHeader}</Grid>}
                    </Grid>
                    {useCollapse && (
                        <IconButton
                            icon={collapsed ? "expand_more" : "expand_less"}
                            onClick={() => toggleCollapsed(!collapsed)}
                            color="#fff"
                        />
                    )}
                </Grid>
            </Paper>
            {useCollapse ? <Collapse in={!collapsed}>{children}</Collapse> : children}
        </Paper>
    );
};

export default View;
