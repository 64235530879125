import { jsonFetcher } from "api";
import { Fetcher } from "api-types";
import { queryClient } from "components-ts/providers/ApiProvider";

type validationRequestOptions<
    TResponse = {},
    TQueryParams extends Fetcher.QueryParams = {},
    TBody extends Fetcher.Body = {},
> = Fetcher.RequestBase & {
    method?: Fetcher.Method;
    queryParams?: TQueryParams;
    body?: TBody;
    silentError?: boolean;
    responseType?: "json" | "formData";
};

const validationRequest = async <TResponse = {}, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>(
    queryConfig: validationRequestOptions<TResponse, TQueryParams, TBody>
) => {
    const { queryParams, body, headers, url } = queryConfig;

    return await queryClient.fetchQuery<TResponse, Response, TResponse, unknown[]>({
        queryFn: () => {
            const { responseType = "json" } = queryConfig;
            if (responseType == "json") {
                return jsonFetcher(
                    {
                        ...queryConfig,
                        method: queryConfig.method || "GET",
                        silentError: queryConfig.silentError || false,
                    },
                    queryParams,
                    body
                );
            }

            //TODO other response type ex. FormData
            throw new Error("Not implemented");
        },
        queryKey: [url, ...Object.values(queryParams || {}), ...Object.values(body || {}), ...Object.values(headers || {})] as unknown[],
    });
};

export default validationRequest;
