/* eslint-disable @typescript-eslint/no-explicit-any */
import { ControllerProps, FieldPath, FieldValues, Controller as HookController } from "react-hook-form";
import { usePrefixContext } from "components-ts/forms/contexts";
import { default as ControlRoot } from "./ControlRoot";

type Props<T, TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = Omit<
    ControllerProps<TFieldValues, TName>,
    "defaultValue" | "disabled"
> & {
    transform?: (value?: T) => T;
    useGridItem?: boolean;
    defaultValue: T | any;
    emptyValue?: T | any;
    fullWidth?: boolean;
    width?: "fullWidth" | "standard" | "double" | "triple" | "auto" | number;
    readOnly?: boolean;
};

const Controller = <T, TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    render,
    defaultValue,
    transform,
    useGridItem,
    emptyValue,
    width,
    readOnly,
}: Props<T, TFieldValues, TName>): JSX.Element => {
    const prefix = usePrefixContext();
    const key = prefix ? `${prefix}.${name}` : `${name}`;

    return (
        <HookController<TFieldValues, TName>
            key={key}
            name={prefix ? (`${prefix}.${name}` as TName) : name}
            defaultValue={defaultValue}
            shouldUnregister={!/\.?\d\./.test(key)}
            render={({ field: { onChange, ...fieldRest }, fieldState, formState }) => {
                return (
                    <ControlRoot
                        useGridItem={useGridItem}
                        width={width}
                    >
                        {render({
                            field: {
                                ...fieldRest,
                                onChange: (value) => {
                                    const transformedValue = transform ? transform(value) : value;
                                    if (value || value === 0 || value === false) {
                                        onChange(transformedValue);
                                    } else {
                                        onChange(emptyValue === undefined ? "" : emptyValue);
                                    }
                                },
                            },
                            fieldState,
                            formState,
                        })}
                    </ControlRoot>
                );
            }}
            disabled={readOnly === true ? true : undefined}
        />
    );
};

export default Controller;
