import { AsyncValidator } from "fluentvalidation-ts";
import { ChargeKindDocument } from "Operations";
import { notEmptyString } from "utils-ts/validations";
import validationRequest from "api/requests/validationRequest";

class ChargeKindDocumentValidator extends AsyncValidator<ChargeKindDocument> {
    constructor() {
        super();

        this.ruleFor("name").must(notEmptyString).maxLength(100);

        this.ruleFor("name").mustAsync({
            predicate: async (value, document) => {
                if (!value) {
                    return true;
                }

                const nameTaken = await validationRequest<boolean>({
                    app: "operations",
                    url: `api/charge-kinds/validate-name/${value}${document.id ? "?chargeKindId=" + document.id : ""}`,
                });

                return !nameTaken;
            },
            message: "Nazwa zajęta",
        });

        this.ruleFor("chargeDocument").must(notEmptyString);

        this.ruleFor("chargeType").must(notEmptyString);
    }
}

export default ChargeKindDocumentValidator;
