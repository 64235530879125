import Layout from "components/Layout/Layout";
import { Fragment } from "react";
import { Suspense } from "react";
import { RouteObject } from "react-router-dom";
import { callbackLocation, silentRenewLocation } from "utils-ts/auth/userManager";
import Loading from "views-ts/loading/Loading";
import { AuthenticationGuard, CallbackPage, SilentRenewPage } from "components-ts/auth";
import { ManagerRoutes } from "./ManagerRoutes";

const createRoutes = (): RouteObject[] => {
    const subRoutes = ManagerRoutes.flatMap((r) => r.subpaths);
    const routes = ManagerRoutes.concat(subRoutes)
        .filter((r) => Boolean(r.component))
        .flatMap((r) => r.GetReactRoutes())
        .filter((r) => r !== null && r !== undefined);

    const mainRoutes: RouteObject[] = [
        {
            path: "/",
            element: <Layout />,
            children: [
                {
                    index: true,
                    element: <AuthenticationGuard component={Fragment} />,
                },
                {
                    path: callbackLocation,
                    element: <CallbackPage />,
                },
                {
                    path: silentRenewLocation,
                    element: <SilentRenewPage />,
                },
                ...routes.map((r) => ({
                    path: r?.path,
                    element: <Suspense fallback={<Loading />}>{r?.element}</Suspense>,
                })),
            ],
        },
    ];

    return mainRoutes;
};

export default createRoutes;
