import { makeStyles } from "tss-react/mui";
import React from "react";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
import { CheckBoxProps } from "control-types";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";

const useStyles = makeStyles()(() => ({
    label: {
        padding: 0,
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em",
    },
}));

const Checkbox: React.FC<CheckBoxProps> = ({
    error,
    label,
    value,
    onChange,
    required,
    size,
    readOnly,
    indeterminate,
    classes,
    sx,
    labelClasses,
    labelPlacement,
}) => {
    const { classes: baseClasses, cx } = useStyles();

    return (
        <>
            <FormControlLabel
                classes={{
                    label: cx(baseClasses.label, labelClasses?.root),
                }}
                labelPlacement={labelPlacement ?? "end"}
                label={label}
                required={required}
                control={
                    <MuiCheckbox
                        checked={value}
                        onChange={(_event, checked) => {
                            if (indeterminate !== undefined) {
                                if (!value && indeterminate) {
                                    onChange(undefined);
                                } else {
                                    onChange(checked);
                                }
                            } else {
                                onChange(checked);
                            }
                        }}
                        size={size}
                        indeterminate={indeterminate}
                        disabled={readOnly}
                        classes={classes}
                        sx={sx}
                        indeterminateIcon={<DisabledByDefaultIcon />}
                    />
                }
            />
            <FormHelperText error={error} />
        </>
    );
};

export default Checkbox;
