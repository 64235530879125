import { forwardRef } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { FormControlProps } from "./types";
import { TextFieldProps } from "control-types";
import { TextWithLabel } from "components-ts/text";
import TextField from "../../controls/inputs/TextField";
import { default as Controller } from "./Controller";

type FormDecimalFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = FormControlProps<
    number,
    TFieldValues,
    TName
> & {
    readOnly?: boolean;
    onCustomChange?: (value: number | undefined, name: string) => void;
};

const TextFieldProxy = forwardRef<
    HTMLDivElement,
    Omit<TextFieldProps, "onChange"> & { onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void }
>(({ onChange, ...props }, ref) => {
    return (
        <TextField
            ref={ref}
            onChange={(_newValue, event) => {
                if (event) {
                    onChange(event);
                }
            }}
            {...props}
        />
    );
});

const FormDecimal = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    emptyValue,
    width,
    readOnly,
    useGridItem,
    onCustomChange,
}: FormDecimalFieldProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, onChange, disabled, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <NumericFormat
                        getInputRef={ref}
                        {...fieldProps}
                        allowNegative
                        allowedDecimalSeparators={[",", "."]}
                        decimalScale={2}
                        onValueChange={(value) => {
                            onChange(value.floatValue);
                            if (onCustomChange) {
                                onCustomChange(value.floatValue, name);
                            }
                        }}
                        label={label}
                        disabled={readOnly}
                        customInput={TextFieldProxy}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val || val === 0 ? Number(val) : emptyValue)}
            useGridItem={useGridItem}
            width={width}
            readOnly={readOnly}
        />
    );
};

export default FormDecimal;
