import { generatePath } from "react-router-dom";
import { ChargesRegisterDocument, ChargesRegisterRequest, ChargesRegisterResponse } from "Operations";
import { useCreateOrUpdateMutation, useDeleteMutation, useFileQuery, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/charges-register/:id?`, { id: id ? id : null });

export const useFindChargesRegisters = (initialParams: ChargesRegisterRequest) => {
    return useGetPagedQuery<ChargesRegisterResponse, ChargesRegisterRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetChargesRegister = (id?: string) => {
    return useGetQuery<ChargesRegisterDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useIsAnyChargesRegisterOpen = (id?: string, recipientId?: string) => {
    return useGetQuery<string>({
        app: "operations",
        url: `api/recipients/${recipientId}/has-register${id ? "?registerId=" + id : ""}`,
        shouldMakeRequest: Boolean(recipientId),
    });
};

export const useCreateOrUpdateChargesRegister = (id?: string) => {
    return useCreateOrUpdateMutation<ChargesRegisterDocument, ChargesRegisterDocument>({ app: "operations", url: url(id), id: id });
};

export const useCloseChargesRegister = (id?: string) => {
    return useCreateOrUpdateMutation<ChargesRegisterDocument, ChargesRegisterDocument>({ app: "operations", url: url(id) + "/close", id: id });
};

export const useDeleteChargesRegister = (id?: string) => {
    return useDeleteMutation({
        app: "operations",
        url: url(id),
    });
};

export const useExportChargesRegisters = (initialParams: Omit<ChargesRegisterRequest, "pageIndex" | "pageSize">, isExporting: boolean) => {
    return useFileQuery({
        app: "operations",
        url: url() + "/export",
        fileName: "export danych.xlsx",
        queryParams: initialParams,
        shouldMakeRequest: isExporting,
    });
};

export const useExportChargesRegistersHeaders = (initialParams: Omit<ChargesRegisterRequest, "pageIndex" | "pageSize">, isExporting: boolean) => {
    return useFileQuery({
        app: "operations",
        url: url() + "/export-headers",
        fileName: "export nagłówków.xlsx",
        queryParams: initialParams,
        shouldMakeRequest: isExporting,
    });
};
