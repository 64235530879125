import type { ContentData } from "Commerce-Core";
import fileTypes from "consts-ts/fileTypes";
import moment from "moment";
import { ResponseModel } from "Shared";
import { enqueueSnackbar } from "notistack";
import { Fetcher, FriscoCustomHeaders } from "api-types";
import getUser from "utils-ts/auth/getUser";
import { dateToString, isDate } from "utils-ts/functions";

class ResponseError extends Error {
    response: Response;
    constructor(message: string, res: Response) {
        super(message);
        this.response = res;
    }
}

const isContentData = (value: unknown): value is ContentData[] => {
    return Array.isArray(value) && value.every((v) => typeof v === "object" && "key" in v && "index" in v);
};

const toQueryParameter = (key: string, value: Fetcher.BaseTypes) => {
    if (value === null || value === undefined) {
        return "";
    }

    if (moment.isMoment(value)) {
        return `${key}=${value.format("YYYY-MM-DDTHH:mm:ss")}`;
    }

    if (isDate(value)) {
        return `${key}=${dateToString(value)}`;
    }

    return `${key}=${encodeURIComponent(value)}`;
};

const combineQueryParameters = (queryParameters?: Fetcher.QueryParams) => {
    if (!queryParameters) {
        return "";
    }

    const queryString = Object.keys(queryParameters)
        .filter((key) => {
            const value = queryParameters[key];
            return Boolean(value) || value === false;
        })
        .map((key) =>
            Array.isArray(queryParameters[key])
                ? (queryParameters[key] as Fetcher.BaseTypes[]).map((i: Fetcher.BaseTypes) => toQueryParameter(key, i)).join("&")
                : toQueryParameter(key, queryParameters[key] as Fetcher.BaseTypes)
        )
        .filter((v) => v !== null && v !== undefined && v.length > 0)
        .join("&");

    return `?${queryString}`;
};

const baseFetcher = async <TResponse = {}, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>(
    request: Omit<Fetcher.Request<TResponse, TQueryParams, TBody>, "headers">,
    params?: TQueryParams,
    body?: TBody,
    friscoCustomHeaders?: FriscoCustomHeaders
): Promise<Response> => {
    const endpoint = [window.config.applications[request.app], request.url, combineQueryParameters(params as Fetcher.QueryParams)].join("");
    const user = getUser();
    const token = user?.access_token;
    let headers: HeadersInit | undefined = undefined;
    if (friscoCustomHeaders) {
        headers = {};
        if (friscoCustomHeaders.commerceWarehouse) {
            headers = {
                ...headers,
                "X-Frisco-Warehouse": Array.isArray(friscoCustomHeaders.commerceWarehouse)
                    ? friscoCustomHeaders.commerceWarehouse.join(",")
                    : friscoCustomHeaders.commerceWarehouse,
            };
        }

        if (friscoCustomHeaders.commerceDateTimeOverride) {
            headers = {
                ...headers,
                "X-Frisco-DateTimeOverride": friscoCustomHeaders.commerceDateTimeOverride?.format("YYYY-MM-DDTHH:mm:ss"),
            };
        }
    }

    const contentType = body instanceof FormData ? undefined : { "content-type": "application/json" };
    const response = await fetch(endpoint, {
        method: request.method,
        headers: {
            ...contentType,
            authorization: `Bearer ${token}`,
            ...headers,
        },
        ...(request.method === "GET" || request.method === "HEAD"
            ? {}
            : {
                  body: JSON.stringify(body, function (key, value) {
                      const val = this[key];
                      if (val === undefined) {
                          return value;
                      } else if (moment.isMoment(val)) {
                          return val.local(true).format("YYYY-MM-DDTHH:mm:ss");
                      } else if (isDate(val)) {
                          return dateToString(val);
                      } else if (key === "contentData" && isContentData(val)) {
                          return val.sort((a, b) => a.index - b.index).reduce((a, v) => ({ ...a, [v.key.trim()]: v.value ?? null }), {});
                      }

                                return value;
                            }),
              }),
    });

    return response;
};

const showErrorMessage = (key: string, message: string) => {
    enqueueSnackbar(message, {
        key: key,
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    });
};

const handleError = async <TResponse = {}>(app: keyof Applications, url: string, error: unknown, silentError: boolean): Promise<TResponse> => {
    console.error({ app, url, error });
    if (error instanceof ResponseError) {
        switch (error.response.status) {
            case 400: {
                const contentType = error.response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    const validationResponse = (await error.response.json()) as ResponseModel;
                    if (validationResponse) {
                        return Promise.reject(validationResponse.errors);
                    }
                }
                break;
            }
            case 404: {
                if (!silentError) {
                    showErrorMessage(`${app}:${url}`, "Zasób nie został odnaleziony");
                }

                return Promise.reject("Not found");
            }
            case 410: {
                if (!silentError) {
                    showErrorMessage(`${app}:${url}`, "Zasób został usunięty");
                }

                return Promise.reject("Gone");
            }
            case 503: {
                if (!silentError) {
                    showErrorMessage(`${app}:${url}`, "Nie udało się zapisać z powodu dużego obciążenia aplikacji, proszę ponowić za chwilę");
                }

                return Promise.reject("Server unabailable");
            }
        }
    }

    if (!silentError) {
        showErrorMessage(
            `${app}:${url}`,
            "Błąd połączenia" + (window.config.env === "Development" || window.config.env === "Development (Static)" ? "\n" + url : "")
        );
    }

    return Promise.reject(error);
};

const blobToBase64 = (blob: Blob, mimeType: string) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const dataUrlPrefix = `data:${mimeType};base64,`;
            const base64WithDataUrlPrefix = reader.result;
            const enc = new TextDecoder("utf-8");
            if (typeof base64WithDataUrlPrefix === "string") {
                const base64 = base64WithDataUrlPrefix?.replace(dataUrlPrefix, "");
                resolve(base64);
            } else {
                const base64 = enc.decode(base64WithDataUrlPrefix || undefined)?.replace(dataUrlPrefix, "");
                resolve(base64);
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const blobFetcher = async <TResponse = Fetcher.FileResponse, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>(
    request: Fetcher.Query<TResponse, TQueryParams, TBody>,
    params?: TQueryParams,
    body?: TBody,
    headers?: FriscoCustomHeaders
): Promise<TResponse> => {
    try {
        const response = await baseFetcher(request, params, body, headers);
        if (!response.ok) {
            throw new ResponseError("Bad fetch response", response);
        }

        const blob = await response.blob();
        if (blob.size > 0) {
            if (request.responseType === "file") {
                const linkElement = document.createElement("a");
                linkElement.setAttribute("href", URL.createObjectURL(blob));
                let fileName = request.fileName;
                if (response.headers.has("content-type")) {
                    const fileType = Object.values(fileTypes).find((f) => f.mimeType === response.headers.get("content-type"));
                    if (fileType && !fileName.endsWith(fileType.extension)) {
                        fileName += `.${fileType.extension}`;
                    }
                }

                linkElement.setAttribute("download", fileName);
                const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                linkElement.dispatchEvent(clickEvent);
            } else if (request.responseType === "base64") {
                const base64 = await blobToBase64(blob, blob.type);

                return { fileContent: base64, fileType: blob.type } as Fetcher.FileResponse as TResponse;
            }
        }

        return { downloaded: true } as Fetcher.FileResponse as TResponse;
    } catch (error: unknown) {
        return handleError(request.app, request.url, error, request.silentError ?? false);
    }
};

export const jsonFetcher = async <TResponse = {}, TQueryParams extends Fetcher.QueryParams = {}, TBody extends Fetcher.Body = {}>(
    request: Fetcher.Request<TResponse, TQueryParams, TBody>,
    params?: TQueryParams,
    body?: TBody,
    headers?: FriscoCustomHeaders
): Promise<TResponse> => {
    try {
        const response = await baseFetcher(request, params, body, headers);
        if (!response.ok) {
            throw new ResponseError("Bad fetch response", response);
        }

        if (response.status === 204) {
            return {} as TResponse;
        }

        const text = await response.text();

        return JSON.parse(text, (key, value) => {
            if (key === "contentData") {
                return Object.keys(value).map((k, i) => ({ index: i, key: k, value: value[k] }) as ContentData);
            }

            if (/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d{1,}\+\d\d:\d\d$/.test(value)) {
                //YYYY-MM-DDTHH:mm:ss.Fzzzz - dateTimeOffset json format with ms, but we dont care about ms
                const date = moment(value.replace(value.slice(value.indexOf("."), value.indexOf("+")), ""), "YYYY-MM-DDTHH:mm:ssZ", true).local(true);
                if (moment.isMoment(date) && date.isValid()) {
                    return date;
                } else if (isDate(value)) {
                    return date.toDate();
                }
            }

            if (/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\+\d\d:\d\d$/.test(value)) {
                //YYYY-MM-DDTHH:mm:sszzzz - dateTimeOffset json format
                const date = moment(value, "YYYY-MM-DDTHH:mm:ssZ", true).local(true);
                if (moment.isMoment(date) && date.isValid()) {
                    return date;
                } else if (isDate(value)) {
                    return date.toDate();
                }
            }

            if (/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d$/.test(value)) {
                //YYYY-MM-DDTHH:mm:ss || YYYY-MM-DD - dateTime/dateOnly json format
                const date = moment(value, "YYYY-MM-DDTHH:mm:ss", true).local(true);
                if (moment.isMoment(date) && date.isValid()) {
                    return date.toDate();
                }
            } else if (/^\d\d\d\d-\d\d-\d\d$/.test(value)) {
                //YYYY-MM-DD - dateTime/dateOnly json format
                const date = moment(value, "YYYY-MM-DD", true).local(true);
                if (moment.isMoment(date) && date.isValid()) {
                    return date.toDate();
                }
            }

            return value;
        }) as TResponse;
    } catch (error: unknown) {
        return handleError(request.app, request.url, error, request.silentError ?? false);
    }
};
