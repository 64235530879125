import { generatePath } from "react-router-dom";
import { RecipientDocument, RecipientRequest, RecipientResponse } from "Operations";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/recipients/:id?`, { id: id ? id : null });

export const useFindRecipients = (initialParams: RecipientRequest) => {
    return useGetPagedQuery<RecipientResponse, RecipientRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useFindRecipient = (id?: string) => {
    return useGetQuery<RecipientDocument>({
        app: "operations",
        url: url(id),
    });
};

export const useCreateOrUpdateRecipient = (id?: string) => {
    return useCreateOrUpdateMutation<RecipientDocument, RecipientDocument>({ app: "operations", url: url(id), id: id });
};
