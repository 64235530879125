import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, HumanResourcesDepartment, LogisticianDepartment, Role } from "../roles";

const RecipientsForm = React.lazy(() =>
    import("views-ts/notesAndBenefits/recipients").then((module) => ({
        default: module.RecipientsForm,
    }))
);

const RecipientsList = React.lazy(() =>
    import("views-ts/notesAndBenefits/recipients").then((module) => ({
        default: module.RecipientsList,
    }))
);

const ChargesRegisterForm = React.lazy(() =>
    import("views-ts/notesAndBenefits/chargesRegister").then((module) => ({
        default: module.ChargesRegisterForm,
    }))
);

const ChargesRegisterList = React.lazy(() =>
    import("views-ts/notesAndBenefits/chargesRegister").then((module) => ({
        default: module.ChargesRegisterList,
    }))
);

const roles: Role[] = [...AccountantDepartment.GetRoles(), ...LogisticianDepartment.GetRoles(), ...HumanResourcesDepartment.GetRoles()];

export const NotesAndBenefits: PrivateRoute[] = [
    new PrivateRoute("/notes-benefits", roles, undefined, "notesAndBenefits", "contacts", [
        new PrivateRoute(Paths.NotesAndBenefits.RecipientsList, roles, RecipientsList, "recipientsList", "face"),
        new PrivateRoute(Paths.NotesAndBenefits.RecipientsForm, roles, RecipientsForm),
        new PrivateRoute(Paths.NotesAndBenefits.ChargesRegisterList, roles, ChargesRegisterList, "chargesRegisterList", "contact_page"),
        new PrivateRoute(Paths.NotesAndBenefits.ChargesRegisterForm, roles, ChargesRegisterForm),
    ]),
];
