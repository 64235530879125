import { CarDocument } from "Operations";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const CarList: React.FC = () => {
    const columns = useColumns<CarDocument>(() => [
        {
            property: "registrationNumber",
            label: "registrationNumber",
            filtrable: true,
        },
        {
            property: "brand",
            label: "brand",
            filtrable: true,
        },
        {
            property: "owner",
            label: "owner",
            filtrable: true,
            filterAs: "select",
            filterItems: [
                { value: "Fraikin", name: "Fraikin" },
                { value: "Frisco", name: "Frisco" },
            ],
        },
        {
            property: "isReplacementCar",
            label: "isReplacementCar",
            as: "boolean",
        },
        {
            property: "isActive",
            label: "isActive",
            as: "boolean",
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "date",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "date",
        },
        {
            actionType: "edit",
            link: (item) =>
                Paths.GeneratePath(Paths.Dictionaries.CarForm, {
                    id: item.id,
                }),
        },
    ]);

    return (
        <View
            headerText={"cars"}
            isMainView
        >
            <QueryTable
                queryHook={api.operations.notesAndBenefits.useGetCars}
                columns={columns}
                formPath={Paths.Dictionaries.CarForm}
            />
        </View>
    );
};

export default CarList;
