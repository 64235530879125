import moment from "moment";
import {
    AnalyticGroupDocument,
    CarDocument,
    ChargeKindDocument,
    DeviceAutocompleteModel,
    DeviceTypeDocument,
    EmployeeAutocompleteResponseModel,
    ProcedureCategoryDocument,
    PromotionPeriodDocument,
    RecipientDocument,
    VendorAutocompleteModel,
    WorkstationDocument,
} from "Operations";
import type { DateTimeOffset } from "Shared";
import { useGetCacheQuery, useGetQuery } from "api/hooks";

const queryKey = "api/autocomplete/";

export const useFindEmployees = () => {
    return useGetCacheQuery<Array<EmployeeAutocompleteResponseModel>>({
        app: "operations",
        url: queryKey + "employees",
    });
};

export const useFindEmployeeDepartments = () => {
    return useGetQuery<Array<string>>({
        app: "operations",
        url: queryKey + "employee-departments",
    });
};

export const useFindProcedureCategories = () => {
    return useGetQuery<Array<ProcedureCategoryDocument>>({
        app: "operations",
        url: queryKey + "procedure-categories",
    });
};

export const useFindDeviceTypes = () => {
    return useGetQuery<Array<DeviceTypeDocument>>({
        app: "operations",
        url: queryKey + "device-types",
    });
};

export const useFindPromotionPeriodsFromDate = (dateFrom?: DateTimeOffset, dateTo?: DateTimeOffset) => {
    dateFrom = dateFrom ?? moment().startOf("day");
    return useGetQuery<Array<PromotionPeriodDocument>>({
        app: "operations",
        url: queryKey + "promotion-periods",
        queryParams: { dateFrom, dateTo },
    });
};

export const useFindVendors = () => {
    return useGetCacheQuery<Array<VendorAutocompleteModel>>({
        app: "operations",
        url: queryKey + "vendors",
        cacheTime: 60,
    });
};

export const useFindAnalyticGroups = () => {
    return useGetCacheQuery<Array<AnalyticGroupDocument>>({
        app: "operations",
        url: queryKey + "analytic-groups",
        cacheTime: 8 * 60,
    });
};

export const useFindAvailableDevices = () => {
    return useGetCacheQuery<Array<DeviceAutocompleteModel>>({
        app: "operations",
        url: queryKey + "devices",
        cacheTime: 5,
    });
};

export const useFindAllWorkstations = () => {
    return useGetCacheQuery<Array<WorkstationDocument>>({
        app: "operations",
        url: queryKey + "workstations",
        cacheTime: 5,
    });
};

export const useFindNotesAndBenefitsRecipients = () => {
    return useGetQuery<Array<RecipientDocument>>({
        app: "operations",
        url: queryKey + "notes-and-benefits-recipients",
    });
};

export const useFindCars = () => {
    return useGetQuery<Array<CarDocument>>({
        app: "operations",
        url: queryKey + "cars",
    });
};

export const useFindChargesKinds = () => {
    return useGetQuery<Array<ChargeKindDocument>>({
        app: "operations",
        url: queryKey + "charges-kinds",
    });
};
